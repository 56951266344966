import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'DetalleMiProgramaPostgradoPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			loginRequest: {},
			errorBag: {},
			MisProgramas:{},
			slide: 0,
			sliding: null,
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			auth: {},
			idProgPer:0,
			idPer:0,
			programaPer:{},
			persona:{},
			archivosPerPro:{},
            nuevaOrden:{},
            urlPago:"",
            botonPago:false,
            pagoExi:{},
            puntoVenta:""
		};

	},
	props: {
		msg: String
	},
	methods: {

		showDetallePersonaProg() {
            
			this.isLoading=true;
			
			let params= {
				idProgPer: this.idProgPer,
				idPer: this.idPer,
			}

            this.ss.showDetallePersonaPrograma(params).then(
                (result) => {
                    let response = result.data;
					this.programaPer = response.data;
					this.getProgramaPersona();

                }
            ).catch(error => {
                console.log(error);
               
            });
		},

 

    

		abrirEdicion(){
            this.$refs['ModalEditar'].show();  
		},

		cancelEdicion(){
            this.$refs['ModalEditar'].hide();
		},

		saveInscripcion() {
			this.archivosPerPro.id = this.programaPer.id;
			
            this.ss.editarArchivosProgramaPersona(this.archivosPerPro).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.showDetallePersonaProg();
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
					console.log(response);
					// this.estaInscrito=true;
                    this.$refs['ModalEditar'].hide();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
		},
		loadFileBoucher() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.archivosPerPro.Boucher = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},
		
		loadFileSolicitud() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.archivosPerPro.Solicitud = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},

		loadFileContrato() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.archivosPerPro.Contrato = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
                // axios.post(urlUploadFile, data)
                //     .then( result => {
                //         if (result.data.success) {
                //             toastr.info(result.data.msg, 'Correcto!');
                //             vm.preinscripcion.ArchivoBoucher = result.data.data;
                //         } else {
                //             toastr.error(result.data.msg, 'Oops!');
                //         }
                //         vm.isLoadingFile = false;
                //     })
                //     .catch( error => {
                //         toastr.error('Error subiendo archivo', 'Oops!');
                //         vm.isLoadingFile = false;
                //         console.log(ruta)
                //     });
            }
		},

        pagarDeNuevo(){

            
            this.nuevaOrden={};
            this.botonPago=true;
            this.urlPago="";

            //datos para la inscripcion
            this.nuevaOrden.Programa=this.programaPer.Programa;
            this.nuevaOrden.Persona=this.programaPer.Persona;
            //this.nuevaOrden.Solicitud=this.programaPersona.Solicitud;
            //this.nuevaOrden.TipoPago=this.programaPersona.TipoPago;

            // datos para la orden de pago

            // console.log("boton de pagos");
            this.nuevaOrden.Amount=this.programaPer.NMatricula*100;
            //this.nuevaOrden.ProgramaPersona=5;
            this.nuevaOrden.AdditionalData=[];
            let aditionalDataItem=["MATRICULA " + this.programaPer.NPrograma, this.programaPer.NMatricula]
            this.nuevaOrden.AdditionalData.push(aditionalDataItem);

            if(this.programaPer.IdUAPrograma==2){
                this.puntoVenta="la paz";
            }else if(this.programaPer.IdUAPrograma==3){
                this.puntoVenta="santa cruz";
            }else if(this.programaPer.IdUAPrograma==4){
                this.puntoVenta="cochabamba";
            }else if(this.programaPer.IdUAPrograma==5){
                this.puntoVenta="riberalta";
            }else if(this.programaPer.IdUAPrograma==6){
                this.puntoVenta="trópico";
            }

            let iDataItems=[
                            ["CELULAR ", this.persona.Celular],
                            ["CARNET ", this.persona.CI],
                            ["NOMBRE COMPLETO", this.persona.Persona],
                            ["EMAIL", this.persona.email],
                            ["NIT", this.persona.FacturaNIT],
                            ["NOMBRE FACTURA", this.persona.FacturaNombre],
                            //["punto_venta", "postgrado"]
                            ["punto_venta", this.puntoVenta]
                            
                           ];
            this.nuevaOrden.IData=iDataItems;

            this.nuevaOrden.InvoiceData={
                "InvoiceDetails":[
                    {
                        "id_concepto":5094, 
                        "descripcion":"MATRICULA " + this.programaPer.NPrograma,
                        "cantidad":100,
                        "precio_unitario":this.programaPer.NMatricula*100,
                        "aplica_descuento":"N",	
                        "porcentaje_descuento":0,
                        "importe_descuento":"0"
                    }
                ]
            };

            //console.log(this.nuevaOrden);
            this.ss.pagarDeNuevo(this.nuevaOrden).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    console.log("inscripcion");
                    console.log(response);
                    
                    // this.$bvToast.toast(
                    //     response.msg,
                    //     {
                    //         title: 'Correcto',
                    //         variant: 'success',
                    //         autoHideDelay: 5000
                    //     }
                    // )
                    // window.open(response.data.item.back_url);
                    // console.log(response.data.item.back_url);
                    this.urlPago = response.data.item.back_url;
                    console.log=(this.urlPago);
                    this.botonPago=false;
                    this.$refs['ModalPago'].show();

                    //window.open(this.urlPago , "ventana1" , "width=500,height=500,scrollbars=NO");
                })
                .catch(error => {
                    //this.errorBag = error.response.data.errors;
                    console.log(error);
                    this.$bvToast.toast(
                        'Ocurrio un error al momento de realizar el pago',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonPago=false;
                });
                //console.log("aaaaaa");
        },

        cancelPago(){
            // this.urlPago="";
            // this.botonPago=false;
            // this.nuevaOrden={};
            // this.$refs['ModalPago'].hide();
            
            location.reload();
        },

        pagoExitoso(){
            console.log("pago exitoso");
            //this.pagoExi.ProgramaPersona=1;

            this.pagoExi.credencial="nmt4ggk7resafbkpirwevnoq";
            this.pagoExi.AuthorizationCode= "831000";
            this.pagoExi.AuthorizationDate="2021/04/18 17:37:41";
            this.pagoExi.BusinessName="no habia";
            this.pagoExi.FormOfPayment="Tarjetas de Debito/Credito";
            this.pagoExi.FormOfPaymentCode="form"
            this.pagoExi.NIT="no hay";
            this.pagoExi.OrderCode="EMIUP3K5";
            this.pagoExi.PaymentData="no hay";
            this.pagoExi.Status="APPROVED";
            this.pagoExi.StatusCode=2;
            this.pagoExi.SystemTraceAuditNumber="INSC_POST_Mon Apr 19 2021 12:55:52 GMT+0000";
            this.pagoExi.TransactionDate="2021/04/18 17:36:29";
            this.pagoExitoso.InvoiceReference="invoice"


            this.ss.notificarPago(this.pagoExi).then(
                (result) => {
                    // console.log(result);
                    let response = result.data;
                    console.log("mandar factura");
                    console.log(response);
                    

                })
                .catch(error => {
                    //this.errorBag = error.response.data.errors;
                    console.log(error);
                    this.$bvToast.toast(
                        'Ocurrio un error al momento de realizar el pago',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.botonPago=false;
                });

        }
        

	},
	mounted() {
		this.persona = JSON.parse(localStorage.getItem("persona"));
		this.auth = JSON.parse(localStorage.getItem("persona"));
		this.idPer = this.auth.id;
		this.idProgPer = this.$route.query.id;
		this.showDetallePersonaProg();
		
	}
}