import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "InteresadosPage",
    data() {
        let ss = new MainService();
        return {
            msg: "InteresadosPage",
            ss: ss,
            ajax: {
                "url": ss.indexInteresados(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'created_at', name: 'created_at', title: 'Fecha Registro' },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'email', name: 'email', title: 'Correo Personal' },
                { data: 'Celular', name: 'Celular', title: 'Celular' },
                // { data: 'Carrera', name: 'Carrera', title: 'Profesion' },
                { data: 'Tecnico', name: 'Tecnico', title: 'Grado Académico' },
                { data: 'Programa', name: 'Programa', title: 'Programa' },


                { data: 'Programa', name: 'Programa', title: 'Programa', render:function(data, type, row){
                    if (row.Programa){
        
                        return  '<div class="text-center text-primary"><p class="text-center"> <b> Tecnico Superior </b></p></div>';
                        
                    }
                    else {
                         return  '<div class="text-center text-success"><p class="text-center"> <b> Licenciatura </b></p> </div>';
                        
                    }
                } },

                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            persona: {},
            // especialidad: {},
            isLoading: false,
            errorBag: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
           
        };
    },
    methods: {

        showInscripcion(id) {
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.$refs['view-inscipcion'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        }, 


        cancelarModalBoucher() {

            this.$refs['view-inscipcion'].hide();
        },

        verDetalles() {
            this.$refs['view-inscipcion'].hide();
            this.$refs['view-inscipcionDetalles'].show();
        },

        draw() {
            window.$('.btn-datatable-Interesados').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showInscripcion(data);
            });
        },
        // draw2() {
        //     window.$('.btn-datatable-VerificarDareValidados').on('click', (evt) => {
        //         const data = window.$(evt.target)[0].id;
        //         this.showInscripcion(data);
        //     });
        // },
    },
    components: {
        dataTable,
        Loader
    },

    computed: {


    },

    mounted() {

        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }
        // else{
        //     this.getNivelAcademico();
        // }
    }
};
