<template>
    <div id="sidebar-menu" align="left">
        <ul>
            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 || auth.Rol == 5 ">
                <router-link
                    class="waves-effect"
                    to="Persona"
                    active-class="active"
                >
                    <i class="fas fa-users"></i>
                    {{ $t('nav.Persona') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol == 1">
                <router-link
                    class="waves-effect"
                    to="UnidadAcademica"
                    active-class="active"
                >
                    <i class="fas fa-university"></i>
                    {{ $t('nav.UnidadAcademica') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol == 1">
                <router-link
                    class="waves-effect"
                    to="Rol"
                    active-class="active"
                >
                    <i class="fas fa-key"></i>
                    {{ $t('nav.Rol') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 3 || auth.Rol == 4 || auth.Rol == 5 || auth.Rol == 6">
                <router-link
                    class="waves-effect"
                    to="Profile"
                    active-class="active"
                >
                    <i class="fas fa-user"></i>
                    {{ $t('nav.Profile') }}
                </router-link>
            </li>


            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="TipoNivelAcademico"
                    active-class="active"
                >
                    <i class="fas fa-sitemap"></i>
                    {{ $t('nav.TipoNivelAcademico') }}
                </router-link>
            </li>

            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="NivelAcademico"
                    active-class="active"
                >
                    <i class="fas fa-graduation-cap"></i>
                    {{ $t('nav.NivelAcademico') }}
                </router-link>
            </li>

            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="Especialidad"
                    active-class="active"
                >
                    <i class="fas fa-bookmark"></i>
                    {{ $t('nav.Especialidad') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="Programa"
                    active-class="active"
                >
                    <i class="fas fa-sitemap"></i>
                    {{ $t('nav.Programa') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="ProgramaPersona"
                    active-class="active"
                >
                    <i class="fas fa-sitemap"></i>
                    {{ $t('nav.ProgramaPersona') }}
                </router-link>
            </li>

            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="VerificarDare"
                    active-class="active"
                >
                    <i class="far fa-check-square"></i>
                    {{ $t('nav.VerificarDare') }}
                </router-link>
            </li>

            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 ">
                <router-link
                    class="waves-effect"
                    to="Interesados"
                    active-class="active"
                >
                    <i class="far fa-check-square"></i>
                    {{ $t('nav.Interesados') }}
                </router-link>
            </li>

            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 5 || auth.Rol == 6 ">
                <router-link
                    class="waves-effect"
                    to="VerificarTesoreria"
                    active-class="active"
                >
                    <i class="far fa-check-square"></i>
                    {{ $t('nav.VerificarTesoreria') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol != 3 ">
                <router-link
                    class="waves-effect"
                    to="Reportes"
                    active-class="active"
                >
                    <i class="fas fa-object-group"></i>
                    {{ $t('nav.Reportes') }}
                </router-link>
            </li>
            <li class="active" v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 4 || auth.Rol == 5">
                <router-link
                    class="waves-effect"
                    to="TipoEstudio"
                    active-class="active"
                >
                    <i class="fas fa-object-group"></i>
                    {{ $t('nav.TipoEstudio') }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Menu",
    data() {
        return {
            msg: "Menu",
            auth: {},
        };
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem("persona"));
    },
};
</script>