import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
//import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoNivelAcademicoPage from '@/pages/TipoNivelAcademico/TipoNivelAcademicoPage.vue'
import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'
import EspecialidadPage from '@/pages/Especialidad/EspecialidadPage.vue'
import ProgramaPage from '@/pages/Programa/ProgramaPage.vue'
import ProgramaPersonaPage from '@/pages/ProgramaPersona/ProgramaPersonaPage.vue'

import VerificarDarePage from '@/pages/VerificarDare/VerificarDarePage.vue'
import InteresadosPage from '@/pages/Interesados/InteresadosPage.vue'
import VerificarDareValidadosPage from '@/pages/VerificarDare/VerificarDareValidadosPage.vue'

import VerificarTesoreriaPage from '@/pages/VerificarTesoreria/VerificarTesoreriaPage.vue'
import ReportesPage from '@/pages/Reportes/ReportesPage.vue'
import VerificarTesoreriaValidadosPage from '@/pages/VerificarTesoreria/VerificarTesoreriaValidadosPage.vue'


import InscribetePage from '@/pages/Inscribete/InscribetePage.vue'
import VerProgramaPostgradoPage from '@/pages/VerProgramaPostgrado/VerProgramaPostgradoPage.vue'
import MisProgramasPostgradoPage from '@/pages/MisProgramasPostgrado/MisProgramasPostgradoPage.vue'
import TipoEstudioPage from '@/pages/TipoEstudio/TipoEstudioPage.vue'
import DetalleMiProgramaPostgradoPage from '@/pages/DetalleMiProgramaPostgrado/DetalleMiProgramaPostgradoPage.vue'

import MiPerfilPage from '@/pages/MiPerfil/MiPerfilPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'PrincipalPage',
    //   component: PrincipalPage
    // },
    {
      path: '/',
      name: 'Inscribete',
      component: InscribetePage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/Inscribete',
      name: 'Inscribete',
      component: InscribetePage
    },
    {
      path: '/TipoNivelAcademico',
      name: 'TipoNivelAcademico',
      component: TipoNivelAcademicoPage
    },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    {
      path: '/Especialidad',
      name: 'Especialidad',
      component: EspecialidadPage
    },
    {
      path: '/Programa',
      name: 'Programa',
      component: ProgramaPage
    },
    {
      path: '/ProgramaPersona',
      name: 'ProgramaPersona',
      component: ProgramaPersonaPage
    },
    {
      path: '/VerificarDare',
      name: 'VerificarDare',
      component: VerificarDarePage
    },
    {
      path: '/VerificarDareValidados',
      name: 'VerificarDareValidados',
      component: VerificarDareValidadosPage
    },
    {
      path: '/VerificarTesoreria',
      name: 'VerificarTesoreria',
      component: VerificarTesoreriaPage
    },

    {
      path: '/Interesados',
      name: 'Interesados',
      component: InteresadosPage
    },

    {
      path: '/VerificarTesoreriaValidados',
      name: 'VerificarTesoreriaValidados',
      component: VerificarTesoreriaValidadosPage
    },
    {
      path: '/VerPrograma',
      name: 'VerPrograma',
      component: VerProgramaPostgradoPage
    },
    {
      path: '/MisProgramas',
      name: 'MisProgramas',
      component: MisProgramasPostgradoPage
    },
    {
      path: '/TipoEstudio',
      name: 'TipoEstudio',
      component: TipoEstudioPage
    },
    {
      path: '/MiPerfil',
      name: 'MiPerfil',
      component: MiPerfilPage
    },
    {
      path: '/DetalleMiPrograma',
      name: 'DetalleMiPrograma',
      component: DetalleMiProgramaPostgradoPage
    },
    {
      path: '/Reportes',
      name: 'Reportes',
      component: ReportesPage
    },
  ]
})