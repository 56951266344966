import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'CI', name: 'p.CI', title: 'Carnet de Identidad' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            isLoadingFile:false,
            isLoadingFile2:false,
            isLoadingFile3:false,
            unidadAcademicas: [],
            carreras: [],
            grados: [],
            rols: [],
            personas: [],
            persona: {},
            isLoading: false,
            errorBag: {},
            password: {},
            tab: 1,
            tab2: 1,
            estudioRealizado:{},
            tiposEstudios:[],
            estPostgraduales:[],
            estGrado:[],
            estEscolar:[],
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        completarDatosAcademicos(){
            this.getEstPostgraduales();
            this.getEstEscolar();
            this.getEstGrado();
            this.$refs['completarDatos'].show();
        },

        agregarEstudios(){
            this.$refs['LLenarDatosAcademicos'].show();
        },
        
        cancelTipoEstudio(){
            this.estudioRealizado={};
            this.$refs['LLenarDatosAcademicos'].hide();
        },

        getTiposEstudios() {
            this.ss.listTipoEstudio().then(
                (result) => {
                    let response = result.data;
                    this.tiposEstudios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getEstPostgraduales() {
            this.ss.listPostgradualesDatoAcademico(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.estPostgraduales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        BorrarEstudioRealizado(id){
            console.log(id);
        },

        getEstGrado() {
            this.ss.listGradoDatoAcademico(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.estGrado = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstEscolar() {
            this.ss.listEscolarDatoAcademico(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.estEscolar = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        

        saveTipoEstudio() {
            this.estudioRealizado.Persona=this.persona.id;
            this.ss.storeDatoAcademico(this.estudioRealizado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.getEstPostgraduales();
                    this.getEstEscolar();
                    this.getEstGrado();
                    this.$refs['LLenarDatosAcademicos'].hide();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
        getGrado(){
            let params= {
                "Fuerza":this.persona.Fuerza
            };
            this.ss.listGrado(params).then(
                (result) => {
                    let response = result.data;
                    this.grados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
            this.tab = 1;
        },
        showPersona(id) {
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.$refs['view-persona'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },
        editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
            this.tab = 1;
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
            this.$refs['frm-persona'].hide();
        },


        buscarSaga() {
			let params = {
				CI: this.persona.CI
			}
            this.ss.buscarSaga(params)
            .then(
                (result) => {
                    let response = result.data;
                    //Si encuentra al usuario...
                    if(response.success == true){
                        this.persona.CodigoSaga = response.data.CodAlumno;
                        this.persona.correoInstitucional = response.data.email_emi;
                        this.persona.Celular = response.data.celular;
                        this.persona.Telefono = response.data.Telefono;
                        this.persona.Direccion = response.data.Direccion;
                    }
                    this.savePersona();
                    console.log(response);
                }
            )
            .catch(error => {
                console.log(error);
                this.savePersona();
               
            });
		},

        savePersona() {
            if(!this.persona.IdGrado){
                this.persona.IdGrado=142;
            }
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-persona'].hide();
                    this.$refs['datatable-persona'].reload();
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersona(this.persona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-persona'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-persona'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-persona'].show();
        },
        changePassword() {
            this.password.Persona = this.persona.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        },

        loadFileCV() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoCV = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFileCarnet() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoCarnet = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileTituloBachiller() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoTituloBachiller = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileTituloProfesional() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoTituloProfesional = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadFileCertificadoNacimiento() {
            //vm.isLoadingFile = true;
            var input = event.target;
            window.e = event.target;
            if (input.files && input.files[0]) {
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        console.log(result);
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.ArchivoCertificadoNacimiento = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getCarrerasPorUnidadAcademica() {
            console.log("Se cambio");
            let idUA = this.persona.UnidadAcademica;
            console.log(this.persona.UnidadAcademica);
            this.ss.listPorCarreraEspecialidadUA(idUA).then(
                (result) => {
                    let response = result.data;
                    //console.log(response);
                    this.carreras = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        
    },
    components: {
        dataTable
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getRol();
            this.getTiposEstudios();
        }
    }
};
